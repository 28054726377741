import React, { FC } from 'react';
import styles from './ImgPlaceholder.module.scss';
import { Icon } from 'semantic-ui-react';

interface ImgPlaceholderProps {
    date?: string;
}

const ImgPlaceholder: FC<ImgPlaceholderProps> = ({ date }) => (
    <div className={styles.imgPlaceholder}>
      {date && <span className={styles.date}>{date}</span>}
      <Icon name="info" size="huge" className={styles.imgPlaceholderIcon} />
    </div>
  );

  export default ImgPlaceholder;