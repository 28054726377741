import { useEffect, useState } from 'react';
import { getNews, getNewsCount } from '../services/news';

const DEFAULT_LIMIT = 8;

export const useNews = (currentPage?: number, getCount?: boolean) => {
  const [news, setNews] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getNews({
        ...(currentPage && { _start: (currentPage - 1) * DEFAULT_LIMIT }),
        _limit: DEFAULT_LIMIT,
      }),
      getCount ? getNewsCount() : undefined,
    ]).then(([news, count]) => {
      setNews(news);
      if (count) setCount(count);
      setIsLoading(false);
    });
  }, [currentPage]);

  return {
    news,
    isLoading,
    nbOfPages: Math.ceil(count / DEFAULT_LIMIT),
  };
};
