import React, { FC, useMemo, useCallback } from 'react';
import { Icon } from 'semantic-ui-react';
import styles from './SingleNews.module.scss';
import Text from '../Text/Text';
import Gap from '../Gap/Gap';
import ImgPlaceholder from '../ImgPlaceholder/ImgPlaceholder';
import { ROUTES } from 'types/routes';

interface SingleNews {
  title: string;
  text: string;
  image?: {
    url?: string;
  };
  updatedAt: string;
  id: string;
}

const SingleNews: FC<SingleNews> = ({ title, text, image, updatedAt, id }) => {
  const style = useMemo(
    () =>
      image && image.url ? { backgroundImage: `url("${image.url}")` } : {},
    [image]
  );
  const date = useMemo(() => new Date(updatedAt).toLocaleDateString(), [
    updatedAt,
  ]);
  const navigate = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.location.href = `${ROUTES.NEWS}?id=${id}`;
    }
  }, []);

  return (
    <div key={title} className={styles.singleNews}>
      {image && image.url ? (
        <div className={styles.img} style={style} onClick={navigate}>
          <span className={styles.date}>{date}</span>
        </div>
      ) : (
        <div className={styles.ImgPlaceholderWrapper} onClick={navigate}>
          <ImgPlaceholder date={date} />
        </div>
      )}
      <Gap top="md" bottom="md">
        <h3>
          <Text
            fontSize="1.3rem"
            weight="bold"
            className={styles.text}
          >
            <span onClick={navigate}>{title}</span>
          </Text>
        </h3>
      </Gap>
      <p>
        <Text alternativeFontFamily>{text.substring(0, 200)} [...] </Text>
        <Icon
          name="angle right"
          className={styles.angleIcon}
          onClick={navigate}
        />
      </p>
    </div>
  );
};

export default SingleNews;
