import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cn from 'classnames';
import styles from './Start.module.scss';
import Header from 'components/common/Header/Header';
import Layout from 'components/common/Layout/Layout';
import Hero from './Hero/Hero';
// import About from './About/About';
import Specializations from './Specializations/Specializations';
import News from './News/News';
import Clients from './Clients/Clients';

export const NEWS = 'news-section';

const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          start {
            heroText
            about {
              headingSmall
              headingLarge
              texts
            }
            specializations {
              headingSmall
              headingLarge
              list {
                title
              }
            }
            news {
              headingSmall
              headingLarge
            }
            clients {
              headingLarge
            }
          }
        }
      }
    }
  }
`;

const Start: FC<{}> = () => {
  const data = useStaticQuery(query);

  return (
    <Layout>
      <Header withUnderlines />
      <main className={styles.main}>
        <section className={cn(styles.section, styles.hero)}>
          <Hero heroTexts={data.allDataJson.edges[0].node.start.heroText} />
        </section>
        <section className={cn(styles.section, styles.news)} id={NEWS}>
          <News {...data.allDataJson.edges[0].node.start.news} />
        </section>
        {/* <section className={cn(styles.section, styles.about)} id={ABOUT}>
          <About {...data.allDataJson.edges[0].node.start.about} />
        </section> */}
        <section className={cn(styles.section, styles.specializations)}>
          <Specializations
            {...data.allDataJson.edges[0].node.start.specializations}
          />
        </section>
        <section className={cn(styles.section, styles.clients)}>
          <Clients {...data.allDataJson.edges[0].node.start.clients} />
        </section>
      </main>
    </Layout>
  );
};

export default Start;
