import React, { FC, useEffect, useState, useCallback } from 'react';
import { Icon } from 'semantic-ui-react';
import { Row, Col } from 'react-flexbox-grid';
import styles from './Hero.module.scss';
import HeroText from './HeroText';
import { NEWS } from '../Start';
// import machine from './images/machine.png';
// import wage from './images/wage.png';
// import comp from './images/comp.png';
import img from './images/grafika-new.png';
import img2 from './images/grafika-new2.png';
import Button from 'components/common/Button/Button';
import { ROUTES } from 'types/routes';

interface HeroProps {
  heroTexts: string[];
}

const Hero: FC<HeroProps> = ({ heroTexts }) => {
  const [imgPositionY, updatePositionY] = useState(
    typeof window !== 'undefined' ? window.scrollY * -0.4 : 0
  );
  const imgStyle = { backgroundPositionY: `${imgPositionY}%` };

  const handleScroll = () => {
    if (typeof window !== 'undefined') {
      updatePositionY(window.scrollY * -0.4);
    }
  };

  const scrollToNextSection = () => {
    const newsSection = document.getElementById(NEWS);
    newsSection.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const goToContactPage = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.location.pathname = ROUTES.CONTACT;
    }
  }, []);

  return (
    <>
      <div className={styles.heroBack} />
      {/* <div className={styles.heroBackImg} /> */}
      <img src={img} alt="back-2" className={styles.heroBackImg} />
      <Row end="lg">
        <Col lg={12} xl={4} className={styles.heroText}>
          <HeroText texts={heroTexts} />
        </Col>
        <Col lg={1} xl={2} />
        <Button
          type="neutral"
          className={styles.goToContactBtn}
          onClick={goToContactPage}
        >
          Skontaktuj się z nami
        </Button>
      </Row>
      <img src={img2} alt="back-2" className={styles.heroImg2} />
      {/* <img src={machine} alt="machine" className={styles.machine} />
      <img src={wage} alt="wage" className={styles.wage} />
      <img src={comp} alt="comp" className={styles.comp} /> */}
    </>
  );
};

export default Hero;
