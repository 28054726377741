import React, { FC } from 'react';
import cn from 'classnames';
import Text from 'components/common/Text/Text';
import styles from './Hero.module.scss';

interface HeroTextProps {
  texts: string[];
}

const HeroText: FC<HeroTextProps> = ({ texts }) => (
  <>
    <Text
      color="neutral"
      block
      fontSize="2.7rem"
      weight="bold"
      className={cn(styles.text, styles.text1)}
    >
      {texts[0]}
    </Text>
    <Text
      color="light"
      block
      fontSize="1.8rem"
      className={cn(styles.text, styles.text2)}
    >
      {texts[1]}
    </Text>
    <Text
      color="neutral"
      block
      fontSize="3.5rem"
      weight="bold"
      className={cn(styles.text, styles.text3)}
    >
      {texts[2]}
    </Text>
    {/* <Text
      color="neutral"
      block
      fontSize="3.5rem"
      weight="bold"
      className={cn(styles.text, styles.text4)}
    >
      {texts[3]}
    </Text> */}
  </>
);

export default HeroText;
