import React, { FC, useCallback } from 'react';
import Gap from 'components/common/Gap/Gap';
import SectionHeading from 'components/common/SectionHeading/SectionHeading';
import styles from './Specializations.module.scss';
import Text from 'components/common/Text/Text';
import { Row, Col } from 'react-flexbox-grid';
import img1 from './images/budynek.png';
import img2 from './images/krawat.png';
import { ROUTES } from 'types/routes';

const iconMap = [
  <img key="1" src={img1} className={styles.icon} />,
  <img key="2" src={img2} className={styles.icon} />,
];

interface SpecializationsProps {
  headingSmall: string;
  headingLarge: string;
  list: {
    title: string;
  }[];
}

const Specializations: FC<SpecializationsProps> = props => {
  const navigate = useCallback(corpo => {
    if (typeof window !== 'undefined') {
      window.location.href = `${ROUTES.SPECIALIZATIONS}?corpo=${corpo}`;
    }
  }, []);

  return (
    <div className={styles.specializations}>
      <div className={styles.imgBack} />
      <div className={styles.back} />
      <Gap bottom="xl">
        <SectionHeading
          // smallHeading={props.headingSmall}
          largeHeading={props.headingLarge}
          color="light"
          centered
        />
      </Gap>
      <Row around="xs" className={styles.specs} middle="xs">
        {props.list.map(({ title }, i) => {
          const icon = iconMap[i];
          return (
            <Col key={title} className={styles.spec}>
              <div
                className={styles.iconWrapper}
                onClick={() => navigate(i === 1)}
              >
                {icon}
              </div>
              <Gap top="lg">
                <Text
                  block
                  color="standard"
                  transform="uppercase"
                  fontSize="1.5rem"
                  align="center"
                  className={styles.title}
                >
                  {title}
                </Text>
              </Gap>
            </Col>
          );
        })}
      </Row>
      {/* <a href="#" className={styles.moreBtn}>
      <Text color="neutral" transform="uppercase" fontSize="1.3rem">
        <Row middle="xs">
          <span>Poznaj szczegóły specjalizacji</span>
          <Gap left="xs">
            <Icon name="angle right" className={styles.angleIcon} />
          </Gap>
        </Row>
      </Text>
    </a> */}
    </div>
  );
};

export default Specializations;
