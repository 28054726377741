import axios from 'axios';
import config from '../config';

export interface GetNewsRequest {
  _limit: number;
  _start: number;
}

export const getNews = (params?: GetNewsRequest) =>
  axios
    .get(`${config.apiUrl}/news`, {
      params: { ...params, _sort: 'createdAt:DESC' },
    })
    .then(({ data }) => data);

export const getNewsCount = () =>
  axios.get(`${config.apiUrl}/news/count`).then(({ data }) => data);

export const getNewsById = (id: string) =>
  axios.get(`${config.apiUrl}/news/${id}`).then(({ data }) => data);
