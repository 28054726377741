import React, { FC, useCallback } from 'react';
import { Row } from 'react-flexbox-grid';
import styles from './News.module.scss';
import Gap from 'components/common/Gap/Gap';
import SectionHeading from 'components/common/SectionHeading/SectionHeading';
import SingleNews from 'components/common/SingleNews/SingleNews';
import Button from 'components/common/Button/Button';
import Spinner from 'components/common/Spinner/Spinner';
import { useNews } from 'hooks/news';
import { ROUTES } from 'types/routes';

interface NewsProps {
  headingSmall: string;
  headingLarge: string;
}

const News: FC<NewsProps> = props => {
  const { news, isLoading } = useNews();

  const goToNewsPage = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.location.href = ROUTES.NEWS;
    }
  }, []);

  return (
    <div className={styles.news}>
      <Gap top="md" bottom="xl">
        <SectionHeading
          // smallHeading={props.headingSmall}
          largeHeading={props.headingLarge}
          color="primary"
          centered
        />
      </Gap>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Row around="xs">
            {!news.length && 'Brak aktualności'}
            {news.slice(0, 3).map(news => (
              <div key={news.title} className={styles.singleNews}>
                <SingleNews {...news} />
              </div>
            ))}
          </Row>
          <Button
            type="neutral"
            className={styles.moreBtn}
            onClick={goToNewsPage}
          >
            Archiwum aktualności
          </Button>
        </>
      )}
    </div>
  );
};

export default News;
