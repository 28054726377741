import axios from 'axios';
import config from '../config';

export const getLogos = () =>
  axios
    .get(`${config.apiUrl}/logos`, { params: { _sort: 'sortingNumber' } })
    .then(({ data }) => data);

export const getOpinions = () =>
  axios
    .get(`${config.apiUrl}/opinions`, { params: { _sort: 'sortingNumber' } })
    .then(({ data }) => data);
