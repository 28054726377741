import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import { Icon } from 'semantic-ui-react';
import Slider from 'react-slick';
import Gap from 'components/common/Gap/Gap';
import SectionHeading from 'components/common/SectionHeading/SectionHeading';
import { getLogos, getOpinions } from 'services/client';
import Spinner from 'components/common/Spinner/Spinner';
import Text from 'components/common/Text/Text';
import styles from './Clients.module.scss';
import { Row } from 'react-flexbox-grid';
import Divider from 'components/common/Divider/Divider';

interface ClientsProps {
  headingLarge: string;
}

const Clients: FC<ClientsProps> = props => {
  const [logos, setLogos] = useState([]);
  const [opinions, setOpinions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const slider = useRef(null);

  useEffect(() => {
    Promise.all([getLogos(), getOpinions()]).then(([logos, opinions]) => {
      setOpinions(opinions);
      setLogos(logos);
      setIsLoading(false);
    });
  }, []);

  const goBack = useCallback(() => {
    if (slider.current) {
      slider.current.slickPrev();
    }
  }, [slider]);

  const goNext = useCallback(() => {
    if (slider.current) {
      slider.current.slickNext();
    }
  }, [slider]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const sliderInterval = setInterval(goNext, 16000);
      return () => clearInterval(sliderInterval);
    }
  }, []);

  return (
    <div className={styles.clients}>
      <Gap top="md" bottom="lg">
        <SectionHeading
          largeHeading={props.headingLarge}
          color="primary"
          centered
        />
      </Gap>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={styles.sliderWrapper}>
          <Icon
            name="chevron left"
            className={styles.chevronLeft}
            onClick={goBack}
          />
          <Slider
            arrows={false}
            dots
            infinite
            centerPadding="20px"
            speed={1000}
            ref={slider}
            className={styles.slider}
          >
            {opinions.map(({ client, opinion }) => (
              <div key={client} className={styles.slide}>
                <div className={styles.client}>
                  <Text
                    className={styles.clientName}
                    fontSize="1.5rem"
                    weight="bold"
                  >
                    {client}
                  </Text>
                  <div className={styles.opinion}>
                    <Icon name="quote left" className={styles.startQuote} />
                    <Text fontStyle="italic" block fontSize="1.3rem">
                      {opinion}
                    </Text>
                    <Icon name="quote right" className={styles.endQuote} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <Icon
            name="chevron right"
            className={styles.chevronRight}
            onClick={goNext}
          />
        </div>
      )}
      <Gap top="xxl" bottom="lg">
        <Divider />
      </Gap>
      <>
        <Text
          block
          fontSize="2rem"
          color="neutral"
          weight="bold"
          transform="uppercase"
        >
          Zaufali nam
        </Text>
        <Row around="xs">
          {logos.map(({ image: { url } }, id) => (
            <img key={id} src={url} alt="logo" className={styles.logo} />
          ))}
        </Row>
      </>
    </div>
  );
};

export default Clients;
